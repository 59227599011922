<!--
* @program: office_automation 
* @author: ly
* @component:OtherOutcome 
* @description: 资金账户支出
* @create: 2022-02-17 09:58
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <a-button @click="refresh" class="search-item" shape="circle" type="dashed">
                    <template #icon><SyncOutlined/></template>
                </a-button>
                <a-button @click="addData" class="search-item" shape="circle" type="dashed">
                    <template #icon><PlusOutlined/></template>
                </a-button>
                <span style="margin-left: 10px">审核状态：</span>
                <a-radio-group @change="radioChange" class="search-item" v-model:value="defaultQuery.approvalState">
                    <a-radio :value="'0'">未完成审核</a-radio>
                    <a-radio :value="'1'">已完成审核</a-radio>
                </a-radio-group>
                <span>金额统计：</span>
                <MonthPicker style="width: 120px" v-model:value="dateS" />
                <MonthPicker :start-month="dateS" style="width: 120px" v-model:value="dateE" />
                <a-button @click="getSumMoney" class="search-item" shape="circle" type="dashed">
                    <template #icon><BarChartOutlined/></template>
                </a-button>
                <a-popover v-if="sumMoney" trigger="hover" placement="bottom">
                    <template #content>
                        <a-list size="small" style="margin: 0" bordered :data-source="sumMoney">
                            <template #renderItem="{item}">
                                <a-list-item>{{item.month}}【{{item.value}}元】</a-list-item>
                            </template>
                        </a-list>
                    </template>
                    <a-button  type="link" >
                        总计：{{sum}}元
                    </a-button>
                </a-popover>
            </div>
            <TwoParts ref="TwoParts" @change="handleChange"  :trans="[50,100]" :enable-change="true">
                <template #left>
                    <TcBaseTable :download="true" :data-source="dataSource" :table-columns="tableColumns" :loading="loading" :page-size="pageSize" :view-model="true" >
                        <template #action="{index,record}">
                            <div class = "action-column">
                                <a-button @click="showDetail(index,record)"
                                          class="action-button" type="link">
                                    <SearchOutlined class="pointer"/>
                                </a-button>
<!--                                -->
                                <a-button @click="editDetail(record)"
                                          v-if="checkAction('edit',record)"
                                          class="action-button" type="link">
                                    <EditFilled class="pointer"/>
                                </a-button>
<!--                                v-if="checkAction('delete',record)"-->
                                <a-popconfirm
                                        @confirm="deleteData(index,record)"
                                        cancel-text="否"
                                        class="action-button"
                                        ok-text="是"
                                        title="删除？">
                                    <DeleteOutlined class="pointer" style="color: red"/>
                                </a-popconfirm>
                            </div>
                        </template>
                    </TcBaseTable>
                </template>
                <template #right>
                    <div v-if="formArg.formData">
                        <a-page-header style="border: 1px solid rgb(235, 237, 240);margin-top: 20px;" :title="rHeader"/>
                        <a-radio-group :disabled="viewModel" v-model:value="currentType" @change="selectType(true)">
                            <a-radio :value="'1'">银行账户间转账</a-radio>
                            <a-radio :value="'2'">由银行卡取出到现金账户</a-radio>
                            <a-radio :value="'3'">由现金账户存入银行卡</a-radio>
                            <a-radio :value="'4'">支出</a-radio>
                        </a-radio-group>
                        <ArForm :argument="formArg" :view-model="viewModel">
                            <template #action="{record}">
                                <a-button @click="saveData(record)" type="primary">
                                    提交
                                </a-button>
                            </template>
                        </ArForm>
                        <a-page-header style="border: 1px solid rgb(235, 237, 240);margin-top: 20px;" title="审核进度"/>
                        <OtherOutcomeAuditStatus :record="formArg.formData"/>
                    </div>
                    <welcome2 v-else/>
                </template>
            </TwoParts>
        </div>
    </div>
</template>

<script>
    ////支出类型（1：卡间相互间转账；2：取现；3：存入，4：对外支出）
    //"银行账户间转账","由银行卡取出到现金账户","由现金账户存入银行卡","支出"
    import MonthPicker from "../../../view/_components/MonthPicker"
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import TwoParts from "../../_components/TwoParts";
    import ICONS from "../../_components/ICONS";
    import ArForm from "../../../components/form/ArForm";
    import item_other_pay from "../../../assets/tables/item_other_pay";
    import otherPay from "../../../assets/api/otherPay";
    import Welcome2 from "../../welcome2";
    import bankBalance from "../../../assets/api/bankBalance";
    import OtherOutcomeAuditStatus from "../OtherOutcomeAudit/OtherOutcomeAuditStatus";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import moment from "moment";
    import api_item_info from "../../../assets/api/itemInfo";
    export default {
        name: "OtherOutcome",
        mixins: [ICONS],
        components: {
            Welcome2,
            TwoParts, TcBaseTable, ArForm,OtherOutcomeAuditStatus,
            MonthPicker
        },
        computed:{
            dataSource(){
                if(this.dateS){
                    if(this.dateE){
                        return this.tableSource.filter(item=>{
                            return  moment(item.payDate).isBetween(moment(this.dateS),moment(this.dateE).add(1, 'month'))
                        });
                    }
                    else{
                        return this.tableSource.filter(item=>{
                            return moment(item.payDate).format("YYYY-MM") === this.dateS;
                        });
                    }
                }
                else {
                    return this.tableSource;
                }
            },
            sum(){
                let sum =0;
                for(let i in this.sumMoney){
                    sum += this.sumMoney[i].value;
                }
                return sum;
            }
        },
        data() {
            return {
                dateS:null,
                dateE:null,
                sumMoney:null,
                table: new item_other_pay(),
                defaultQuery: {
                    approvalState: "0"
                },
                authority: [],
                pageSize: 15,
                queryObject: {}, //用于存储查询数据
                formArg: {
                    formColumns: null, //提供Form的字段集 this.table.getFormColumns()
                    formData: null,//提供Form的formData
                },

                tableSource: null,//提供table的DataSource
                tableColumns: null, //提供table的字-段集 this.table.getTableColumns()
                loading: false,//table的loading状态

                viewModel: true,
                currentType:"1",
                cardAccounts:[],
                moneyAccount:[],
                rHeader:null,
            }
        },
        methods: {
            init() {
                this.dateS = null;
                this.dateE = null;
                this.sumMoney = null;
                let data = {...this.defaultQuery};
                this.formArg.formData = null;
                this.loading = true;
                otherPay.queryAll.requestPOST(this, data, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        for(let i in res.data.data){
                            if(res.data.data[i].payBankName){
                                res.data.data[i].payBankNum = res.data.data[i].payBankName+"【"+res.data.data[i].payBankNum+"】";
                            }
                            if(res.data.data[i].receiveBankName){
                                res.data.data[i].receiveBankNum = res.data.data[i].receiveBankName+"【"+res.data.data[i].receiveBankNum+"】";
                            }
                        }
                        this.tableSource = res.data.data;
                        this.tableSource = this.tableSource.sort((a,b)=>{
                            return b.payDate - a.payDate;
                        })
                    } else {
                        this.tableSource = [];
                    }
                });
            },
            doSearch() {
                let data = {...this.defaultQuery};
                Object.assign(data, this.searchData);
                this.loading = true;
                otherPay.queryAll.requestPOST(this, data, res => {
                    this.loading = false;
                    if (res.data.flag) {
                        this.tableSource = res.data.data;
                    } else {
                        this.tableSource = [];
                    }
                });
            },
            showDetail(index, record) {
                this.viewModel = true;
                this.formArg.formData = record;
                this.rHeader = "支出详情";
                this.currentType = record.type;
                this.selectType();
            },
            editDetail(record){
                this.currentType = record.type;
                this.viewModel = false;
                this.formArg.formData = record;
                this.rHeader = "支出详情";
                this.selectType();
            },
            checkAction(action, record) {
                switch (action) {
                    case "edit": {
                        return record.approvalState === "1";
                    }
                    case "delete": {
                        return record.approvalState === "0";
                    }
                }
            },
            deleteData(index, record) {
                let data = {id: record.id};
                otherPay.delete.requestPOSTUrlParam(this, data, res => {
                    if (res.data.flag) {
                        this.tableSource.splice(record.index, 1);
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.error(res.data.msg)
                    }
                });
            },
            refresh() {
                this.init()
            },
            addData() {
                this.rHeader = "添加新的支出信息";
                this.currentType = "1";
                this.formArg.formData = null;
                this.viewModel = false;
                this.selectType();
                setTimeout(()=>{
                    this.formArg.formData = this.table.getFormData();
                    this.formArg.formData.type = this.currentType;
                })
            },
            radioChange() {
                this.init();
            },
            saveData(record) {
                if(record.id){
                    otherPay.forceUpd.requestPOST(this, record, res => {
                        if (res.data.flag) {
                            this.$message.success(res.data.msg);
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
                }
                else{
                    otherPay.saveOrUpd.requestPOST(this, record, res => {
                        if (res.data.flag) {
                            this.$message.success(res.data.msg);
                            this.init();
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    });
                }

            },
            selectType(bool){
                switch (this.currentType) {//（1：卡间相互间转账；2：取现；3：存入，4：对外支出）
                    case "1":{
                        this.rHeader = "银行账户间转账";
                        this.formArg.formColumns = [
                            new Column("转账类型","categoryId",ColumnType.Giro,true),
                            new Column("支付金额","payAmount",ColumnType.Number,true),
                            new Column("支付日期","payDate",ColumnType.Date,true),
                            new Column("支付账户","payBankId",ColumnType.Selector,true)
                                .setSelectorObject("payBankNum",this.cardAccounts),
                            new Column("接收账户","receiveBankId",ColumnType.Selector,true)
                                .setSelectorObject("receiveBankNum",this.cardAccounts),
                            new Column("说明","remarks",ColumnType.String,true),
                        ];
                        break
                    }
                    case "2":{
                        this.rHeader = "由银行卡取出到现金账户";
                        this.formArg.formColumns = [
                            new Column("转账类型","categoryId",ColumnType.Giro,true),
                            new Column("支付金额","payAmount",ColumnType.Number,true),
                            new Column("支付日期","payDate",ColumnType.Date,true),
                            new Column("支付账户","payBankId",ColumnType.Selector,true)
                                .setSelectorObject("payBankNum",this.cardAccounts),
                            new Column("接收账户","receiveBankId",ColumnType.Selector,true)
                                .setSelectorObject("receiveBankNum",this.moneyAccount),
                            new Column("说明","remarks",ColumnType.String,true),
                        ];
                        break
                    }
                    case "3":{
                        this.rHeader = "由现金账户存入银行卡";
                        this.formArg.formColumns = [
                            new Column("转账类型","categoryId",ColumnType.Giro,true),
                            new Column("支付金额","payAmount",ColumnType.Number,true).setDefaultValue(0).setTableView(100),
                            new Column("支付日期","payDate",ColumnType.Date,true),
                            new Column("支付账户","payBankId",ColumnType.Selector,true)
                                .setSelectorObject("payBankNum",this.moneyAccount),
                            new Column("接收账户","receiveBankId",ColumnType.Selector,true)
                                .setSelectorObject("receiveBankNum",this.cardAccounts),
                            new Column("说明","remarks",ColumnType.String,true),
                        ];
                        break
                    }
                    case "4":{
                        this.rHeader = "支出";
                        this.formArg.formColumns = [
                            new Column("费用类别","categoryId",ColumnType.Category,true).setTableView(100),
                            new Column("所属项目","itemId",ColumnType.IdReplace2,true)
                                .setIdReplace2Object("itemName",{api:api_item_info.queryAll,tag:"name",options:{
                                        "archiveState": "", "auditState": "1", "collectionState": "", "contractState": "",
                                        "itemLeaderName": "", "itemState": "", "name": "", "partyInfoName": "", "qualityState": "",
                                        "startTime": "", "tenderWay": ""
                                    }}),
                            new Column("支付金额","payAmount",ColumnType.Number,true).setDefaultValue(0).setTableView(100),
                            new Column("支付日期","payDate",ColumnType.Date,true).setTableView(100),
                            new Column("支付账户","payBankId",ColumnType.Selector,true).setTableView(100)
                                .setSelectorObject("payBankNum",this.cardAccounts.concat(this.moneyAccount)),
                            new Column("说明","remarks",ColumnType.String,true),
                        ];
                        break
                    }

                }
                if(bool){
                    this.formArg.formData = this.table.getFormData();
                    this.formArg.formData.type = this.currentType;
                }
            },
            getSumMoney(){
                this.sumMoney = [];
                for(let i in this.dataSource){
                    //this.sumMoney +=this.dataSource[i].payAmount;

                    let month = moment(this.dataSource[i].payDate).format("YYYY-MM");
                    let arr = this.sumMoney.filter(item=>item.month === month);
                    if(arr.length >0){
                        arr[0].value += this.dataSource[i].payAmount;
                    }
                    else{
                        this.sumMoney.push({month:month,value:this.dataSource[i].payAmount})
                    }
                }

            },
            handleChange(pos){
                if (pos === "transform") {
                    this.tableColumns = [
                        new Column("序号", "_index", ColumnType.Index, false).setTableView(60),
                        new Column("支付类型","type",ColumnType.Enum)
                            .setEnum(["1","2","3","4"],["银行账户间转账","由银行卡取出到现金账户","由现金账户存入银行卡","支出"],["cyan","blue","green","red"]),
                        new Column("所属项目","itemName",ColumnType.String,true).setTableView(150),
                        new Column("费用类别","categoryId",ColumnType.Category,true).setTableView(100),
                        new Column("支付金额","payAmount",ColumnType.Number,true).setDefaultValue(0).setTableView(100),
                        new Column("支付日期","payDate",ColumnType.Date,true).setTableView(80),
                        new Column("支付账户","payBankNum",ColumnType.String,true).setTableView(200),//payBankNum+payBankName
                        new Column("接收账户","receiveBankNum",ColumnType.String,true).setTableView(200),
                        new Column("说明","remarks",ColumnType.String,true).setTableView(200),
                        new Column("审核状态","approvalState",ColumnType.Enum).setEnum(["0","1"],["待审核","已审核"],["red","green"]).setTableView(80)
                    ]
                } else {
                    this.tableColumns = this.table.getTableColumns();
                }
            }
        },
        created() {
            this.tableColumns = this.table.getTableColumns();
            this.formArg.formColumns = this.table.getFormColumns();
            this.init();
            bankBalance.queryAll.requestPOST(this,{},res=>{
                if(res.data.flag){
                    this.cardAccounts = [];
                    this.moneyAccount = [];
                    for(let i in res.data.data){
                        let bankName = res.data.data[i].bankName;
                        if(bankName === "现金账户"){
                            this.moneyAccount.push({
                                id:res.data.data[i].id,
                                name:bankName
                            })
                        }
                        else{

                            this.cardAccounts.push({
                                id:res.data.data[i].id,
                                name:bankName +"【"+ res.data.data[i].bankNum + "】"
                            })
                        }
                    }
                    this.table.getFormColumns().filter(item=>item.dataIndex === "payBankId")[0]
                        .setSelectorObject("payBankNum",this.cardAccounts);
                    this.table.getFormColumns().filter(item=>item.dataIndex === "receiveBankId")[0]
                        .setSelectorObject("receiveBankNum",this.cardAccounts);
                }
                else{
                    this.$message.error(res.data.msg);
                }
            });
        }
    }
</script>

<style scoped>

</style>