<!--
* @program: office_automation
* @author: ly
* @component:MonthArrange
* @description: 周报
* @create: 2022-05-20 15:46
-->
<template>
    <a-spin :spinning="spinning">
        <div >
            <TcBaseTable :title="true" :table-columns="tableColumns" :data-source="dataSource"
                         ref="weeks_table" :scroll="{x:200,y:200}"
                         :view-model="true" :page-size="pageSize">
                <template #title>
                    <div style="text-align: left">
                        <a-week-picker v-model:value="queryDate" placeholder="请选择周查询" @change="init"/>
                        <a-button @click="addData" type="primary" style="margin-left: 10px">
                            <template #icon><PlusOutlined /></template>
                        </a-button>
                    </div>
                </template>
                <template #action="{index,record}">
                    <div class="action-column">
                        <a-button class="action-button" type="link"  @click="showData(record)">
                            <SnippetsOutlined class="pointer" title="查看"/>
                        </a-button>
                        <a-button v-if="record.writerState == '0'" class="action-button" type="link"  @click="editData(record)">
                            <EditFilled class="pointer" title="修改"/>
                        </a-button>
                        <a-popconfirm
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定提交吗?"
                                v-if="record.writerState == '0'"
                                @confirm="submit(record)">
                            <UpCircleOutlined style="color: #154a80" class="pointer" title="提交"/>
                        </a-popconfirm>
                        <a-popconfirm
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定撤回吗?"
                                v-if="record.writerState == '1' && record.deptApproveState != '1'"
                                @confirm="cancel(record)">
                            <RollbackOutlined style="color: #af9e45" class="pointer" title="撤回"/>
                        </a-popconfirm>
                        <a-popconfirm
                                ok-text="是"
                                cancel-text="否"
                                class="action-button"
                                title="确定删除吗?"
                                v-if="record.writerState == '0'"
                                @confirm="deleteData(index,record)">
                            <DeleteOutlined  style="color: red" title="删除" class="pointer"/>
                        </a-popconfirm>
                    </div>
                </template>
            </TcBaseTable>
        </div>
    </a-spin>

    <a-modal v-model:visible="visible"
             width="900px"
             title="周报">
            <a-form
                    name="custom-validation"
                    ref="formRef"
                    :model="formData"
                    :rules="rules"
                    style="height:600px;overflow: auto"
            >
                <a-row>
                    <a-col :span="12">
                        <a-form-item  label="周报时间" name="selectedDate" ref="selectedDate">
<!--                            <a-week-picker v-if="edit == 'add'" v-model:value="selectedDate" placeholder="请选择周报时间" />-->
                            <a-input  v-model:value="formData.weekDate" :disabled="true" />
                        </a-form-item>
                    </a-col>
                    <a-col :span="12">
                        <a-form-item  label="总体完成进度" name="completeRatio">
                            <a-input v-model:value="formData.completeRatio" :disabled="true" />
                        </a-form-item>
                    </a-col>
                </a-row>


                <a-form-item  label="本周工作情况" name="thisWeekWork" ref="thisWeekWork" >
                    <a-textarea v-model:value="formData.thisWeekWork"  :disabled="edit == 'show'" placeholder="本周工作情况" :rows="4" />
                </a-form-item>
                <a-form-item  label="下周工作计划" name="nextWeekWork" ref="nextWeekWork" >
                    <a-textarea v-model:value="formData.nextWeekWork" :disabled="edit == 'show'" placeholder="下周工作计划" :rows="4" />
                </a-form-item>
                <a-form-item  label="目前存在问题" name="existProblem" ref="existProblem" >
                    <a-textarea v-model:value="formData.existProblem" :disabled="edit == 'show'" placeholder="目前存在的问题" :rows="4" />
                </a-form-item>
                <a-form-item  label="目前应对措施" name="solveMethod" ref="solveMethod" >
                    <a-textarea v-model:value="formData.solveMethod" :disabled="edit == 'show'" placeholder="目前应对措施" :rows="4" />
                </a-form-item>
                <a-form-item  label="备注" name="remarks">
                    <a-textarea v-model:value="formData.remarks" :disabled="edit == 'show'"  :rows="4" />
                </a-form-item>
                <a-form-item v-if="formData.deptApproveState == '1'"  label="部门领导审核备注" name="solveMethod">
                    <a-textarea v-model:value="formData.deptApproveRemark" :disabled="edit == 'show'" placeholder="目前应对措施" :rows="4" />
                </a-form-item>

                <a-form-item v-if="formData.deptApproveState == '1'"  label="总经办审阅意见" name="solveMethod">
                    <a-textarea v-model:value="formData.reviewSugg" :disabled="edit == 'show'" placeholder="目前应对措施" :rows="4" />
                </a-form-item>
            </a-form>

            <template #footer>
                <a-button type="primary" html-type="submit" v-if="edit != 'show'" @click="saveData">保存</a-button>
            </template>
    </a-modal>
</template>

<script>
    import moment from "moment"
    import {SearchOutlined,DeleteOutlined,PlusOutlined,SnippetsOutlined,
        EditFilled,UpCircleOutlined,RollbackOutlined} from "@ant-design/icons-vue"
    import TcBaseTable from "../../../components/table/TcBaseTable";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import weekWork from "../../../assets/api/weekWork";
    import api_item_task from "../../../assets/api/itemTask";
    import invoiceRecord from "../../../assets/api/invoiceRecord";
    export default {
        name: "weeksReport",
        props:["record"],
        components:{
            TcBaseTable,PlusOutlined,EditFilled,DeleteOutlined,UpCircleOutlined,RollbackOutlined,
            SnippetsOutlined
        },
        data(){
            return{
                spinning:false,
                pageSize:10,
                loading:false,
                dataSource: [],
                defaultOptions:{
                    weekDate:null,
                },
                visible:false,
                edit:'',
                queryDate:null,
                selectedDate: null,
                dateFormat: 'YYYY-WW',
                formData:{
                    id:null,
                    taskId:null,
                    weekDate:null,
                    thisWeekWork:null,
                    nextWeekWork:null,
                    completeRatio:null,
                    existProblem:null,
                    solveMethod:null,

                    reviewSugg:null,
                    remarks:null,
                },
                completeRatio:null,
                rules:{
                   /* selectedDate:{
                        required: true,
                        message: '请选择周报时间',
                    },*/
                    thisWeekWork:{
                        required: true,
                        message: '请输入本周工作情况',
                    },
                    nextWeekWork:{
                        required: true,
                        message: '请输入下周工作计划',
                    },
                    existProblem:{
                        required: true,
                        message: '请输入目前存在的问题',
                    },
                    solveMethod:{
                        required: true,
                        message: '请输入目前应对措施',
                    },
                },
            }
        },
        created() {
            this.tableColumns = [
                new Column("序号","_index",ColumnType.Index).setTableView(60),
                new Column("周报时间","weekDate",ColumnType.String,true).setTableView(80),
                new Column("完成进度","completeRatio",ColumnType.String,true).setTableView(80),
                new Column("审核状态","deptApproveState",ColumnType.String).setTableView(80)
                    .setEnum(["0","1","2"],["未审核","通过","不通过"],["pink","green","red"]),
                new Column("本周工作情况","thisWeekWork",ColumnType.TextArea,false).setTableView(100).setRows(4),
                new Column("下周工作计划","nextWeekWork",ColumnType.TextArea,true).setTableView(100).setRows(4),
                new Column("目前存在的问题","existProblem",ColumnType.TextArea,false).setTableView(100).setRows(4),
                new Column("目前应对措施","solveMethod",ColumnType.TextArea,false).setTableView(100).setRows(4),
                new Column("操作","actions","actions",false).setTableView(100),
            ];
            this.init();
            let data = {
                id:this.record.id
            }
            api_item_task.queryOne.requestPOSTUrlParam(this,data,res=>{
                if(res.data.flag){
                    this.completeRatio = res.data.data.completeRatio;
                }
            });

        },
        methods:{
            init(){
                this.dataSource = [];
                this.loading = true;
                let data = {
                    taskId:this.record.id
                };
                if(this.queryDate){
                    let year = moment(this.queryDate).format('YYYY');
                    let week = moment(this.queryDate).week()// .format('WW');
                    data.weekDate = year + '年第' + week + "周";
                }
                weekWork.queryAll.requestPOST(this,data,res=>{
                    if(res.data.flag){
                        this.dataSource = res.data.data;
                        this.$refs.weeks_table.setTotalSize(res.data.count);
                        this.$refs.weeks_table.setCurrentPage(1);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                    this.loading = false;
                })
            },
            addData(){
                this.formData = {};
                this.formData.completeRatio = this.completeRatio;
                this.edit = 'add';
                let date = new Date();
                let year = moment(date).format('YYYY');
                let week = moment(date).week()
                this.formData.weekDate = year + '年第' + week + "周";
                this.visible = true;
            },
            editData(record){
                this.edit = 'edit';
                this.visible = true;
                this.formData = {...record};
            },
            showData(record){
                this.edit = 'show';
                this.visible = true;
                this.formData = {...record};
            },
            saveData(){
                /*if(this.edit == 'add' && this.selectedDate != null){
                    let year = moment(this.selectedDate).format('YYYY');
                    let week = moment(this.selectedDate).week()//.format('WW');
                    this.formData.weekDate = year + '年第' + week + "周";
                }*/

               /* if(this.formData.weekDate == null || this.formData.weekDate == ''){
                    this.$message.info("请选择周报时间");
                    return;
                }*/

                if(this.formData.thisWeekWork == null || this.formData.thisWeekWork == ''){
                    this.$message.info("请输入本周工作情况");
                    return;
                }

                if(this.formData.nextWeekWork == null || this.formData.nextWeekWork == ''){
                    this.$message.info("请输入下周工作计划");
                    return;
                }

                if(this.formData.existProblem == null || this.formData.existProblem == ''){
                    this.$message.info("请输入目前存在的问题");
                    return;
                }

                if(this.formData.solveMethod == null || this.formData.solveMethod == ''){
                    this.$message.info("请输入目前应对措施");
                    return;
                }

                this.formData.taskId = this.record.id;


                weekWork.saveOrUpd.requestPOST(this,this.formData,res=>{
                    if(res.data.flag){
                        this.$message.success(res.data.msg);
                        this.visible = false;
                        this.init();
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                })
            },
            submit(record){
                let data = {
                    id:record.id
                }
                weekWork.submit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init();
                    }
                });
            },
            cancel(record){
                let data = {
                    id:record.id
                }
                weekWork.cancelSubmit.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.init();
                    }
                });
            },
            deleteData(index,record){
                let data = {id:record.id};
                weekWork.delete.requestPOSTUrlParam(this,data,res=>{
                    if(res.data.flag){
                        this.dataSource.splice(index,1);
                        this.$message.success(res.data.msg);
                    }
                    else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
        },

    }
</script>

<style scoped>

</style>
