<!--
* @program: tyh-oa 
* @author: ly
* @component:billStatisticsByCategory 
* @description: 按报账类别统计账单
* @create: 2021-06-11 11:51
-->
<template>
    <div class="oa-content">
        <div class="oa-window">
            <div class="search-bar">
                <div style="margin: 8px;font-weight: 900;font-size: 16px"> 统计方式：</div>
                <a-select @select="selectChange" class="search-item" style="width: 200px" v-model:value="type">
                    <a-select-option :key="seg" v-for="{seg,label} in options">
                        {{label }}
                    </a-select-option>
                </a-select>
                <div ref="i_selector" v-is="currentComp"/>
                <a-button @click="doSearch" class="search-item" shape="circle" style="margin-left: 20px" type="dashed">
                    <template #icon>
                        <SearchOutlined/>
                    </template>
                </a-button>

                <a-button @click="tipsShow" class="search-item" type="link">
                    <template #icon>
                        <InfoCircleOutlined/>
                    </template>
                </a-button>
            </div>
            <div class="oa-window">
                <a-row style="height: 900px;overflow: auto">
                    <a-col :span="16">
                        <div class="ant-row divhover" style="height: 350px;box-shadow: 0px 0px 5px #888888;">
                            <div ref="chart_xmlb" style="height:100%;width:100% "></div>
                        </div>
                        <div class="ant-row divhover"
                             style="height: 450px;margin-top: 10px; box-shadow: 0px 0px 5px #888888;">
                            <div ref="chart_xmlb_sj" style="height:100%;width:100%"></div>
                        </div>
                    </a-col>
                    <a-col :span="7"
                           style="box-shadow: 0px 0px 5px #888888;margin-left: 10px;">
                        <div ref="chart_bz_lb" style="height:100%;width:100% "></div>
                    </a-col>
                </a-row>

            </div>


            <div class="loading" v-if="loadingVisible">
                <a-space>
                    <a-spin size="large"/>
                </a-space>
            </div>
        </div>
    </div>

    <a-drawer :closable="false"
              placement="right"
              v-model:visible="visible_info"
              width="1200px"
    >
        <div>合计：{{sum.toFixed(2)}}</div>
        <TcBaseTableFilter :data-source="ds" :download="true" :loading="loading" :page-size="20"
                           :table-columns="tableColumns" :view-model="true"/>
    </a-drawer>
</template>

<script>
    import categoryChart from "./categoryChart";
    import api_fundSummary from "../../../assets/api/fundSummary";
    import {
        DownloadOutlined,
        InfoCircleOutlined,
        PlusOutlined,
        SearchOutlined,
        SyncOutlined
    } from "@ant-design/icons-vue"
    import monthSelector from "../../../components/toolBar/monthSelector";
    import yearSelector from "../../../components/toolBar/yearSelector";
    import periodSelector from "../../../components/toolBar/periodSelector";
    import userInfo from "../../../assets/api/userInfo";
    import otherPay from "../../../assets/api/otherPay";
    import payApply from "../../../assets/api/payApply";
    import {timeIsBetween} from "../../../assets/utils/general";
    import category from "../../../assets/api/category";
    import {markRaw} from "vue";
    import * as echarts from "echarts";
    import moment from "moment";
    import Column from "../../../assets/tables/parts/column";
    import ColumnType from "../../../assets/tables/parts/column_type";
    import recordFlow from "../../../assets/api/recordFlow";
    import TcBaseTableFilter from "../../../components/table/TcBaseTableFilter";

    export default {
        name: "billStatisticsByCategory",
        components: {
            categoryChart, DownloadOutlined, InfoCircleOutlined,
            SearchOutlined, PlusOutlined, SyncOutlined, monthSelector, yearSelector, periodSelector, TcBaseTableFilter
        },

        data() {
            return {
                loadingVisible: false,
                options: [
                    {seg: "month", label: "按月统计", comp: "monthSelector"},
                    {seg: "year", label: "按年统计", comp: "yearSelector"},
                    {seg: "period", label: "按时间段统计", comp: "periodSelector"}
                ], //[{seg:"title",label:"账单标题",type:"input"}]
                currentComp: "monthSelector",
                type: "month",

                nonZeroDatas: [],//剔除掉 值0 的类别
                modalVisible: false,
                queryOptions: {startMonth: "", endMonth: ""},
                chartTitle: "",
                queryPeriod: [],
                allUsers: [],

                headerTree: [],
                exceptSeg: [//不纳入统计支出的费用类别
                    //转账类
                    "5dd9e0f4-dafe-41fc-a795-338ea9a1ee3d",//银行账户间转账
                    "2f650b0e-696e-4a90-940e-3e1a76f7b81d",//由银行账户提现
                    "ed04edf5-5e9e-4fb6-9bf5-1ead8288df51",//存入银行账户
                    //收入类
                    "5d24423a-06a1-42a4-a705-37b5b6448bb5",//房租
                    "c3a7ff4f-d889-4ee5-b644-ee3eedf49605",//酒
                    "f8f5c6d1-0458-4849-a6fe-9fdeefda4d6f",//其他
                    "e5cedb3c-e44c-4297-8682-919b4cdc9ec6",//退税
                    "7f377f5e-5c65-46b6-af67-473533760d3f",//退社保
                    "5f4ae192-8b7e-4814-a034-c8dc7cc41dc8",//银行利息
                    "2320930e-2f05-4841-aae8-908223837047",//保证金
                    //其他资金中转
                    "a60f25e2-359e-4bd6-86ba-a6d86caf3532",//保证金
                    "7129b1d8-49fb-4d8d-b989-d89449854df5",//借款
                    "1a6f83f8-359b-41fe-8407-aac55257060d",//还款
                    "414b5883-186e-41b7-b530-4fc44f46015a",//其他

                    "56f82486-58c4-460d-863a-3eb510976bf2",//保证金
                    "757063b0-015f-41dc-9eea-6a90fe5510de",//保证金
                ],


                otherPay_data: [],
                payApply_data: [],
                fundSummary_data: [],
                charts_xmlb: null,
                charts_xmlb_sj: null,
                charts_bz_lb: null,
                chartsCurrentData_lb: null,
                chartsCurrentData_fy: null,
                visible_info: false,
                dataSource: [],
                loading: false,
            }
        },
        computed: {
            setting() {
                let titles = [];
                let datas = [];
                for (let i in this.nonZeroDatas) {
                    titles.push(this.nonZeroDatas[i].title);
                    datas.push(this.nonZeroDatas[i].children)
                }
                return {titles, datas}
            },
            ds() {
                return this.dataSource.filter(item => {
                    let time = new moment(item.flowTime);
                    return time.isBetween(new moment(this.queryPeriod[0]).startOf("month").format("YYYY-MM-DD"), new moment(this.queryPeriod[1]).endOf("month").format("YYYY-MM-DD"), null, '[]')
                })
            },
            sum() {
                let sum = 0;
                for (let i in this.ds) {
                    if (this.ds[i].applyAmount) {
                        sum += this.ds[i].applyAmount;
                    } else if (this.ds[i].payAmount) {
                        sum += this.ds[i].payAmount
                    }
                }
                return sum;
            }
        },
        created() {
            this.loadingVisible = true;
            userInfo.queryAll.requestPOSTUrlParam(this, {disableState: "1", loginState: "0"}, res => {
                if (res.data.flag) {
                    this.allUsers = res.data.data
                } else {
                    this.allUsers = [];
                }
            });
            category.queryOneLevel.requestPOST(this, {}, res => {
                if (res.data.flag) {
                    this.headerTree = res.data.data;
                }
            })

            let param2 = {approvalState: "1"};
            this.otherPay_data = otherPay.queryAll.requestPOSTAsync(param2);
            let param3 = {payState: "1"};
            this.payApply_data = payApply.queryAll.requestPOSTAsync(param3);
            setTimeout(() => {
                this.doSearch();
            }, 1000);
        },
        methods: {
            async doSearch() {
                this.nonZeroDatas = [];
                let data = this.$refs.i_selector.getValue();
                switch (this.type) {
                    case "month": {
                        this.queryOptions = {startMonth: data, endMonth: data};
                        this.queryPeriod = [data, data];
                        break;
                    }
                    case "year": {
                        this.queryOptions = {startMonth: data + "-01", endMonth: data + "-12"};
                        this.queryPeriod = [data + "-01", data + "-12"];
                        break;
                    }
                    case "period": {
                        this.queryOptions = {startMonth: data[0], endMonth: data[1]};
                        this.queryPeriod = data;
                        break;
                    }
                }
                let res1 = await api_fundSummary.statisticMonth.requestPOSTUrlAsync(this.queryOptions);
                this.loadingVisible = false;
                this.fundSummary_data = res1.data;
                this.applySetting(res1.data, this.otherPay_data.data, this.payApply_data.data);
            },

            selectChange(seg) {
                let option = this.options.filter(op => {
                    return op.seg === seg
                })[0];
                this.currentComp = option.comp;
            },

            tipsShow() {
                this.$message.info("金额统计只统计已入账的账单，未入账的账单不进行统计")
            },
            applySetting(data1, data2, data3) {
                let allSum = 0;
                if (data1) {
                    let categoryTempVOS = data1.categoryTempVOS;
                    let sumExpendTempVOS = data1.sumExpendTempVOS;
                    // 排序
                    sumExpendTempVOS.sort(function (a, b) {
                        return a.yearMonth > b.yearMonth ? 1 : -1
                    });
                    this.eachar_xmlb_sj(sumExpendTempVOS);
                    for (let i in categoryTempVOS) {
                        let item = {
                            name: categoryTempVOS[i].categoryName,
                            title: categoryTempVOS[i].categoryName + "【" + categoryTempVOS[i].sumExpend + "】",
                            sumBar: categoryTempVOS[i].sumExpend,
                            children: []
                        };
                        if (categoryTempVOS[i].sumExpend > 0) {
                            let children = categoryTempVOS[i].children;
                            let tempNonZeroData = [];
                            for (let j in children) {
                                let child = {
                                    query: true,
                                    id: children[j].id,
                                    name: children[j].categoryName,
                                    value: children[j].sumExpend
                                };
                                if (children[j].sumExpend) {
                                    tempNonZeroData.push(child);
                                }
                            }
                            item.children = tempNonZeroData;
                            this.nonZeroDatas.push(item)
                        }
                    }
                    for (let j in sumExpendTempVOS) {
                        allSum += sumExpendTempVOS[j].sumMoney;
                    }
                }
                let children = [];
                let sum = 0;
                let sum2 = 0;
                if (data2) {
                    for (let i in data2) {
                        if (timeIsBetween(data2[i].payDate, this.queryPeriod) && (this.exceptSeg.indexOf(data2[i].categoryId) === -1)) {
                            data2[i].flowTime = data2[i].payDate;
                            sum += data2[i].payAmount;
                            let arr = children.filter(item => item.name === data2[i].categoryName);
                            if (arr.length > 0) {
                                arr[0].value += data2[i].payAmount;
                                arr[0].children.push(data2[i]);
                            } else {
                                children.push({
                                    id: data2[i].categoryId,
                                    name: data2[i].categoryName,
                                    value: data2[i].payAmount,
                                    children: [data2[i]]
                                })
                            }
                        }
                    }
                    if (data3) {
                        for (let i in data3) {
                            if (timeIsBetween(data3[i].payTime, this.queryPeriod)) {
                                data3[i].flowTime = data3[i].payTime;
                                sum += data3[i].payAmount;
                                sum2 += data3[i].payAmount;
                            }
                        }
                        children.push({id: "xmzf", name: "项目外协支付", value: sum2, children: data3})
                    }
                    let item = {
                        name: "银行直付",
                        title: "银行直付【" + sum.toFixed(2) + "】",
                        sumBar: sum.toFixed(2),
                        children: children
                    };
                    this.nonZeroDatas.push(item);
                    allSum += sum;
                }
                this.chartTitle = "合计：【" + allSum.toFixed(2) + "】";
                this.eachar_xmlb(this.nonZeroDatas);
                setTimeout(() => {
                    this.eachar_bz_lb(this.nonZeroDatas.length > 0 ? this.nonZeroDatas[0] : null);
                }, 200);


            },


            /******* 统计图 start *******/
            eachar_xmlb(data) {
                if (this.charts_xmlb) {
                    this.charts_xmlb.clear();
                }
                let option = null;
                if (data.length <= 0) {
                    option = {
                        title: {
                            extStyle: {
                                color: "grey",
                                fontSize: 20

                            },
                            textStyle: {
                                color: "#FFD700",
                            },

                            text: "报账类别统计：暂无数据",

                            left: "center",

                            top: "center"

                        },
                    }
                } else {
                    let data_x = [];
                    let data_y = [];
                    data.forEach((item, index) => {
                        data_x.push(item.name);
                        data_y.push(item.sumBar)
                    })
                    option = {
                        title: {
                            text: "报账类别统计",
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            },
                            formatter: (params) => {
                                var result = "报账类别统计" + '<br>'
                                params.forEach(function (item) {
                                    if (item.value) {
                                        result += item.marker + ' ' + item.name + ' : ' + item.value + '</br>'
                                    }
                                })
                                // 保留数据
                                this.chartsCurrentData_lb = params[0]
                                // 返回mousemove提示信息
                                return result
                            }
                        },
                        legend: {},
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                data: data_x,
                                name: '类别',
                                axisLine: {//x轴线的颜色以及宽度
                                    show: true,
                                    lineStyle: {
                                        color: "blue",
                                        width: 0,
                                        type: "solid",
                                    },

                                },
                                axisLabel: {
                                    textStyle: {
                                        color: 'blue',
                                        fontSize: 20,

                                    }
                                },
                                nameTextStyle: {
                                    color: '#A9A9A9',
                                    padding: [100, 0, 0, 0],		//表示[上,右,下,左]的边距
                                },
                                triggerEvent: true,
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '(金额)',
                                nameTextStyle: {
                                    color: '#A9A9A9',
                                    padding: [100, 0, 0, 15],		//表示[上,右,下,左]的边距
                                }
                            }
                        ],
                        series: [
                            {
                                type: 'bar',
                                emphasis: {
                                    focus: 'series'
                                },
                                label: {
                                    show: true,
                                },
                                data: data_y,
                            },
                        ]
                    };
                }

                this.charts_xmlb = markRaw(echarts.init(this.$refs.chart_xmlb))//获取dom元素;
                this.charts_xmlb.setOption(option);


                /** 扩大点击范围 start **/
                // 防止点击调用多次
                this.charts_xmlb.getZr().off('click')
                // 鼠标移动到阴影范围 setCursorStyle('pointer')
                this.charts_xmlb.getZr().on('mousemove', param => {
                    var pointInPixel = [param.offsetX, param.offsetY];
                    if (this.charts_xmlb.containPixel('grid', pointInPixel)) {//若鼠标滑过区域位置在当前图表范围内 鼠标设置为小手
                        this.charts_xmlb.getZr().setCursorStyle('pointer')
                    } else {
                        this.charts_xmlb.getZr().setCursorStyle('default')
                    }
                })
                this.charts_xmlb.getZr().on('click', params => {

                    // 获取到鼠标点击位置：
                    const pointInPixel = [params.offsetX, params.offsetY]
                    // 判断点击位置是否在显示图形区域
                    if (this.charts_xmlb.containPixel('grid', pointInPixel)) {
                        // 刷新报账分析图

                        let list = this.nonZeroDatas;
                        list.forEach((item, index) => {
                            if (item.name == this.chartsCurrentData_lb.axisValue) {
                                this.eachar_bz_lb(item);
                            }
                        })
                    }
                })
                /** 扩大点击范围 end **/

            },
            eachar_xmlb_sj(data) {
                if (this.charts_xmlb_sj) {
                    this.charts_xmlb_sj.clear();
                }
                let option = null;
                if (data.length <= 0) {
                    option = {
                        title: {
                            extStyle: {
                                color: "grey",
                                fontSize: 20

                            },
                            textStyle: {
                                color: "#FFD700",
                            },

                            text: "报账类别时间曲线统计：暂无数据",

                            left: "center",

                            top: "center"

                        },
                    }
                } else {
                    let data_x = [];
                    let data_y = [];
                    data.forEach((item, index) => {
                        data_x.push(item.yearMonth);
                        data_y.push(item.sumMoney)
                    })
                    option = {
                        title: {
                            text: "报账类别时间曲线统计",
                        },
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'shadow'
                            },
                            formatter: (params) => {
                                var result = '报账类别时间曲线统计' + '<br>'
                                params.forEach(function (item) {
                                    if (item.value) {
                                        result += item.marker + ' ' + item.name + ' : ' + item.value + '</br>'
                                    }
                                })
                                // 返回mousemove提示信息
                                return result
                            }
                        },
                        legend: {},
                        grid: {
                            left: '3%',
                            right: '4%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                //data: ['2016', '2017', '2018', '2019', '2020', '2021', '2022'],
                                data: data_x,
                                name: '月',
                                axisLine: {//x轴线的颜色以及宽度
                                    show: true,
                                    lineStyle: {
                                        color: "blue",
                                        width: 0,
                                        type: "solid",
                                    },

                                },
                                axisLabel: {
                                    textStyle: {
                                        color: 'blue',
                                        fontSize: 20,

                                    }
                                },
                                nameTextStyle: {
                                    color: '#A9A9A9',
                                    padding: [100, 0, 0, 0],		//表示[上,右,下,左]的边距
                                },
                                triggerEvent: true,
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '(金额)',
                                nameTextStyle: {
                                    color: '#A9A9A9',
                                    padding: [100, 0, 0, 15],		//表示[上,右,下,左]的边距
                                }
                            }
                        ],
                        series: [
                            {
                                type: 'line',
                                emphasis: {
                                    focus: 'series'
                                },
                                label: {
                                    show: true,
                                },
                                data: data_y
                            },
                        ]
                    };
                }

                this.charts_xmlb_sj = markRaw(echarts.init(this.$refs.chart_xmlb_sj))//获取dom元素;
                this.charts_xmlb_sj.setOption(option);
            },
            eachar_bz_lb(data) {
                if (this.charts_bz_lb) {
                    this.charts_bz_lb.clear();
                }
                let option = null;
                if (data == null) {
                    option = {
                        title: {
                            extStyle: {
                                color: "grey",
                                fontSize: 20

                            },
                            textStyle: {
                                color: "#FFD700",
                            },

                            text: "费用类别统计：暂无数据",

                            left: "center",

                            top: "center"

                        },
                    }
                } else {
                    let title_name = data.name;
                    let list_data = data.children;

                    option = {
                        title: {
                            text: title_name,
                            /*  subtext: 'Fake Data',*/

                        },
                        tooltip: {
                            trigger: 'item',

                        },
                        legend: {
                            top: 'bottom',
                        },
                        series: [
                            {
                                name: title_name,
                                type: 'pie',
                                radius: '40%',
                                data: list_data,
                                itemStyle: {
                                    normal: {
                                        label: {
                                            show: true,
                                            formatter: '{b} : {c} ({d}%)'  //展示的文字   类型+百分比
                                        },
                                        labelLine: {show: true}
                                    }
                                },
                                emphasis: {
                                    itemStyle: {
                                        shadowBlur: 10,
                                        shadowOffsetX: 0,
                                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                                    }
                                }
                            }
                        ]
                    };
                }
                this.charts_bz_lb = markRaw(echarts.init(this.$refs.chart_bz_lb));
                this.charts_bz_lb.setOption(option);

                this.charts_bz_lb.on('click', (params) => {
                    console.log(params);
                    this.handleClick(params);
                });
            },

            handleClick(event) {
                this.dataSource = [];
                this.loading = true;
                if (event.data.query) {
                    this.tableColumns = [
                        new Column("序号", "_index", ColumnType.Index).setTableView(60),
                        new Column("用户名", "createUserName", ColumnType.String, true).setTableView(80),
                        new Column("费用类别", "categoryId", ColumnType.Category, true).setTableView(200),
                        new Column("支出时间", "flowTime", ColumnType.Date, true).setTableView(80),
                        new Column("项目支付", "itemExpenditure", ColumnType.Boolean, true).setTableView(80),
                        new Column("项目", "itemName", ColumnType.String, true).setTableView(200),
                        new Column("发票金额(元)", "invoiceAmount", ColumnType.Number, true).setTableView(80),
                        new Column("报销金额(元)", "applyAmount", ColumnType.Number, true).setTableView(80),
                        new Column("备注", "remarks", ColumnType.String, true).setTableView(150)
                    ];
                    this.visible_info = true;
                    let data = {
                        categoryId: event.data.id,
                        entryState: "1"
                    };

                    recordFlow.queryAll.requestPOSTUrlParam(this, data, res => {
                        if (res.data.flag) {
                            this.loading = false;
                            this.dataSource = res.data.data;
                            for (let i in this.dataSource) {
                                let createUser = this.dataSource[i].createUser;
                                let arr = this.allUsers.filter(item => item.id === createUser);
                                if (arr.length > 0) {
                                    this.dataSource[i].createUserName = arr[0].name;
                                } else {
                                    this.dataSource[i].createUserName = "已删除";
                                }
                            }
                        } else {
                            this.loading = false;
                            this.dataSource = []
                        }
                    })
                } else {
                    if (event.data.name === "项目外协支付") {
                        this.tableColumns = [
                            new Column("序号", "_index", ColumnType.Index).setTableView(60),
                            new Column("项目", "itemName", ColumnType.String, true).setTableView(200),
                            new Column("付款合同名称", "contractName", ColumnType.String, true).setTableView(200),
                            new Column("收款单位/个人", "outSourceName", ColumnType.String, true).setTableView(100),
                            new Column("支付金额", "payAmount", ColumnType.Number, true).setDefaultValue("0").setTableView(100),
                            // new Column("付款公司", "payCompanyId", ColumnType.Enum, true).setTableView(150)
                            //     .setEnum(companyIds, companyNames,companyColors),
                            new Column("付款部门", "applyDepartName", ColumnType.String, true).setTableView(100),
                            //new Column("支付备注","remarks",ColumnType.String,true).setTableView(100),
                            new Column("支付描述", "payReason", ColumnType.String, true).setTableView(100),
                        ]
                    } else {
                        this.tableColumns = [
                            new Column("序号", "_index", ColumnType.Index, false).setTableView(60),
                            // new Column("支付类型","type",ColumnType.Enum).setTableView(150)
                            //     .setEnum(["1","2","3","4"],["银行账户间转账","由银行卡取出到现金账户","由现金账户存入银行卡","支出"],["cyan","blue","green","red"]),
                            new Column("项目", "itemName", ColumnType.String, true).setTableView(200),
                            new Column("费用类别", "categoryId", ColumnType.Category, true).setTableView(150),
                            new Column("支付金额", "payAmount", ColumnType.Number, true).setTableView(100),
                            new Column("支付日期", "payDate", ColumnType.Date, true).setTableView(100),
                            new Column("支付账户", "payBankNum", ColumnType.String, true).setTableView(200),
                            new Column("说明", "remarks", ColumnType.String, true).setTableView(200),
                        ]
                    }
                    this.visible_info = true;
                    this.dataSource = event.data.children;
                    for (let i in this.dataSource) {
                        let createUser = this.dataSource[i].createUser;
                        let arr = this.allUsers.filter(item => item.id === createUser);
                        if (arr.length > 0) {
                            this.dataSource[i].createUserName = arr[0].name;
                        } else {
                            this.dataSource[i].createUserName = "已删除";
                        }
                    }
                    this.loading = false;
                }


            },
            /******* 统计图 end *******/

        },

    }

</script>

<style scoped>
    .loading {
        position: fixed;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(44, 62, 80, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

</style>